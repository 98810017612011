@import "../_colors.scss";

$padding: 10px 10px;
$action-btn-width: 65px;

.topbar {
  display: grid;
  min-height: 50px;
  background: $magnify-color-ui-blue;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;

    .icon {
      height: 2rem;
      width: 2rem;
    }
  }
}

.message {
  display: flex;
  justify-content: center;
  margin-top: 30vh;
  align-items: center;
  font-size: 20px;
}

.route-btn {
  all: unset;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  background: #008fbe;
  font-size: 20px;
  left: 100%;
  width: 150px;
  padding: 3px;
  margin: 10px;

  &:hover {
    background: #006b8f;
  }
}
