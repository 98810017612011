$magnify-color-ui-gray-01: #585858;
$magnify-color-ui-gray-02: #cecece;
$magnify-color-ui-gray-03: #f4f4f4;
$magnify-color-ui-gray-04: #f5f5f5;
$magnify-color-ui-gray-05: #e7e7e7;

$magnify-color-text-gray-01: #585858;
$magnify-color-text-gray-02: #686868;
$magnify-color-text-gray-03: #acacac;

$magnify-color-ui-blue: #000e;
$magnify-color-ui-blue-02: rgba(97, 93, 89, 0.048);
$magnify-color-ui-blue-07: #8e8884;
$magnify-color-ui-blue-03: #a19a94;
$magnify-color-ui-blue-04: #dedcd7;
$magnify-color-ui-blue-05: #615d59;
$magnify-color-ui-blue-06: #827c7873;

$magnify-color-ui-green: rgb(18, 136, 71);
$magnify-color-ui-red: rgb(192, 50, 24);
