@import "./colors";
@import "./theme";
@import "~searchkit/theming/theme.scss";

.hide {
  display: none;
}

.bold {
  display: inline;
  font-weight: 600;

  &.number {
    font-size: 1.2rem;
  }
}
//this is the color used by the highlight function in elastic search
em {
  background: #e6bb89;
}

.sk-search-box.is-focused {
  background: transparent;
}

.sk-panel {
  padding-bottom: 2rem;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  .filter--policytype {
    font-size: 12px;
  }

  .filter--master-toggle {
    width: fit-content;
  }
}

.sk-layout__filters {
  display: none;
}

.background {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}

.sk-select {
  padding-left: 1rem;
  max-height: unset;
  select {
    font-weight: 200;
    color: $magnify-color-ui-gray-01;
    padding: 6px 10px;
    width: fit-content;
    cursor: pointer;
    &:hover {
      background: $magnify-color-ui-gray-03;
    }
  }

  &:after {
    display: none;
  }
}

.sk-action-bar {
  margin: 0;

  .sk-action-bar-row {
    position: relative;
    height: 100%;
  }

  .sk-filter-groups {
    margin: 0;

    .sk-filter-group-items__value {
      font-weight: 600;
    }
  }

  .sk-filter-group {
    font-size: 12px;
    line-height: 8px;
    border-radius: 20px;
    background: white;
    user-select: none;
    white-space: nowrap;

    .sk-filter-group__remove-action {
      visibility: hidden;
      position: relative;
      border: none;
      margin-left: 0;

      // Use 'x' unicode character
      &::before {
        visibility: visible;
        position: absolute;
        content: "\00D7";
      }
    }
  }

  .sk-panel__header {
    display: block;
  }

  .sk-toggle {
    margin-left: 0;
    width: fit-content;
  }
}

.sk-spinning-loader {
  flex-shrink: 0;
  margin-right: 9px;
  margin-bottom: 11px;
}

.sk-reset-filters,
.sk-reset-filters.is-disabled {
  display: none;
}

.results-bar {
  margin-bottom: 5px;
}

.italic {
  font-style: italic;
}

.rc-slider-mark {
  top: 16px;
}

.rc-slider {
  background: #fff;
}

.logout-button {
  border-radius: 3px;
  border: 0;
  background: #fff;
  height: 100%;
  margin-left: 10px;
  font-size: inherit;
}

.logout-button:hover {
  background: #fa2a00;
  color: white;
}

.doc_matches li {
  font-size: 17px;
  list-style: none;
  margin-bottom: 0.8em;
  color: #aaa;
}

.doc_matches li > span {
  font-style: italic;
  line-height: 1.4em;
  color: #000;
}

.doc_matches li > span::before,
.doc_matches li > span::after {
  content: "...";
}

.doc_matches ul {
  padding-left: 15px;
  max-width: 800px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.metadata-bar {
  margin: 10px -0.61em 0 -0.6em;
  font-size: 0.9em;
}

div.right-align {
  margin-left: auto;
}

.description-paragraph {
  margin-bottom: 1em;
}
