@import "./colors";

$sk-bold-weight: 700 !default;
$sk-medium-weight: 500 !default;
$sk-light-weight: 300 !default;

$sk-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !default;

$sk-layout-background: white !default;
$sk-layout-panel-background: white !default;
$sk-primary-theme-color: #2a4466 !default;
$sk-active-bg-color: $magnify-color-ui-blue;
$sk-border-color: #ccc !default;
$sk-title-color: black !default;
$sk-text-color: #444 !default;
$sk-text-secondary-color: #777 !default;
$sk-list-text-color: #666 !default;
$sk-list-secondary-text-color: #ccc !default;
$sk-action-text-color: $magnify-color-ui-blue;
$sk-action-active-color: #000 !default;
$sk-disabled-text-color: #ddd !default;
$sk-remove-action-color: #fa2a00 !default;

$sk-selector-background: white !default;
$sk-selector-active-background: $magnify-color-ui-blue;
$sk-selector-border-color: #ccc !default;
$sk-selector-text-color: black !default;
$sk-selector-active-text-color: white !default;

$sk-selected-filters-background: white !default;

$sk-heading-font-size: 14px !default;
$sk-text-font-size: 14px !default;
$sk-list-font-size: 14px !default;
$sk-action-font-size: 14px !default;
$sk-list-secondary-font-size: 12px !default;

$sk-border-radius: 5px;
