@import "../colors";

.loader {
  /* Light grey */
  border: 4px solid #dddcdc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45vh;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
